@font-face {
  font-family: 'sf-mono';
  src: url('./assets/sf-mono/SF-Mono-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'gothic-a1';
  src: url('./assets/gothic-a1/GothicA1-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}