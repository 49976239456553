@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-3xl mt-8 mb-4 text-gray-200 font-medium;
    }
    h2 {
        @apply text-xl mt-6 mb-3 text-gray-200;
    }
    p {
        @apply text-base mt-4 text-gray-400;
    }
    ul {
        @apply list-disc list-outside pl-4 text-gray-400;
    }
    ol {
        @apply list-decimal list-outside pl-4 text-gray-400;
    }
    b, strong {
        @apply font-medium text-gray-200 !important;
    }
    img {
        @apply py-4;
    }
    ClickableImage {
        @apply py-4;
    }
    ::selection {
        @apply bg-blue-950;
    }
    .text-container {
        @apply w-full lg:max-w-prose px-4 pt-4 sm:pb-16 sm:px-12 md:pt-8;
    }
    .caption {
        @apply font-sf-mono text-xs uppercase;
    }
    
}
@layer components {
    .body-content a {
        @apply border-b border-dashed pb-0.5 border-gray-600;

    }
}